import React, { PropsWithChildren } from "react";

type Props = {};

const Main = (props: PropsWithChildren<Props>) => {
  const { children } = props;

  return <main className="main">{children}</main>;
};

export default Main;
