import clsx from 'clsx';
import React from 'react';

import { transformToHTML5 } from '@utils/html';

type Props = { html?: string; className?: string };

const RawHtml = (props: Props) => {
  const { html = '', className } = props;

  return (
    <div
      className={clsx('flow', className)}
      dangerouslySetInnerHTML={{ __html: transformToHTML5(html) }}
    ></div>
  );
};

export default RawHtml;
