import CustomScripts from '@components/CustomScripts';
import React, { PropsWithChildren } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Main from '../Main';

type Props = {};

const Layout = (props: PropsWithChildren<Props>) => {
  const { children } = props;

  return (
    <div className="layout">
      <Header
        /** @ts-expect-error */
        data={children.props.menuData}
        /** @ts-expect-error */
        header={children.props.headerData}
      />
      <Main>{children}</Main>
      {/** @ts-expect-error */}
      <Footer data={children.props.footerData} />
      <CustomScripts />
    </div>
  );
};

export default Layout;
