import { ParsedUrlQuery } from 'querystring';

function removeBaseUrl(url: string) {
  return url.replace(process.env.NEXT_PUBLIC_BASE_URL ?? '', '');
}

function addBaseUrl(url: string) {
  return `${process.env.NEXT_PUBLIC_BASE_URL ?? ''}${url}`;
}

function toQuerystring(query: ParsedUrlQuery) {
  return Object.entries(query)
    .filter(Boolean)
    .map(entry => entry.join('='))
    .join('&');
}

export { addBaseUrl, removeBaseUrl, toQuerystring };
