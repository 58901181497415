import React, { HTMLAttributes } from 'react';

type Props = {} & HTMLAttributes<HTMLButtonElement>;

const Hamburger = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  return (
    <button className="hamburger" ref={ref} {...props}>
      <span></span>
    </button>
  );
});

Hamburger.displayName = 'Hamburger';

export default Hamburger;
