import React from 'react';
import RawHtml from '@components/RawHtml';
import Link from 'next/link';
import Image from 'next/legacy/image';
import { addBaseUrl, removeBaseUrl } from '@/utils/url';
import { WagtailImage } from '@/types';

type SocialMediaData = {
  image: WagtailImage | null;
  link: string;
  title: string;
  id: number;
  svg: string | null;
  ariatext: string;
};

type FooterMenuData = {
  id: number;
  title: string;
  link_intern_full_url: string | null;
  link_url: string | null;
};

type FooterData = {
  id: number;
  text: string | null;
  social_media: SocialMediaData[];
  footer_menu: FooterMenuData[];
};

type Props = {
  data: FooterData | null;
};

const Footer = (props: Props) => {
  const { data } = props;

  const current = new Date();
  const date = `${current.getFullYear()}`;

  return (
    <footer className="footer">
      <div className="container">
        {data?.text ? (
          <RawHtml className="footer__text" html={data.text} />
        ) : null}

        {Boolean(data?.social_media?.length) ? (
          <div className="footer__social">
            {data?.social_media.map(item => (
              <Link
                key={item.id}
                href={item.link}
                passHref
                className="footer__socialitem"
                aria-label={item.ariatext}
                target="_blank"
                rel="noreferrer"
              >
                {item.image ? (
                  <Image
                    src={addBaseUrl(item.image.meta.download_url)}
                    alt={item.image.title}
                    layout="fixed"
                    className="socialicon"
                    objectFit="contain"
                    width={40}
                    height={40}
                  />
                ) : item.svg ? (
                  <RawHtml className="socialicon" html={item.svg} />
                ) : null}
              </Link>
            ))}
          </div>
        ) : null}
        <div className="footer__menu">
          {data?.footer_menu.length ? (
            <ul className="footer__menu-list" role="list">
              {data.footer_menu.map(entry => (
                <li key={entry.id} className="footer__menu-list-item">
                  <Link
                    href={
                      entry.link_intern_full_url
                        ? removeBaseUrl(entry.link_intern_full_url)
                        : entry.link_url ?? '/'
                    }
                    passHref
                  >
                    {entry.title}
                  </Link>
                </li>
              ))}
            </ul>
          ) : null}
          <p className="footer__copy">© {date} Stadtwerke Witten</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
