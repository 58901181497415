import Script from 'next/script';
import React from 'react';

const Matomo = () => {
  return (
    <>
      {/** Default Matomo Integration */}
      {process.env.NEXT_PUBLIC_MATOMO_ID &&
      process.env.NEXT_PUBLIC_MATOMO_URL ? (
        <Script
          id="matomo"
          dangerouslySetInnerHTML={{
            __html: `var _paq = window._paq = window._paq || [];
_paq.push(["disableCookies"]);
_paq.push(['trackPageView']);
_paq.push(['enableLinkTracking']);
(function() {
  var u="${process.env.NEXT_PUBLIC_MATOMO_URL}";
  _paq.push(['setTrackerUrl', u+'m.php']);
  _paq.push(['setSiteId', '${process.env.NEXT_PUBLIC_MATOMO_ID}']);
  var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
  g.async=true; g.src=u+'m.js'; s.parentNode.insertBefore(g,s);
})();`,
          }}
        />
      ) : null}
      {/** Matomo Tag Manager Integration */}
      {process.env.NEXT_PUBLIC_MTM_ID && process.env.NEXT_PUBLIC_MATOMO_URL ? (
        <Script
          id="mtm"
          dangerouslySetInnerHTML={{
            __html: `var _mtm = window._mtm = window._mtm || [];
_mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
g.async=true; g.src='${process.env.NEXT_PUBLIC_MATOMO_URL}/js/container_${process.env.NEXT_PUBLIC_MTM_ID}.js'; s.parentNode.insertBefore(g,s);`,
          }}
        />
      ) : null}
    </>
  );
};

export default Matomo;
