import React from 'react';
import Link from 'next/link';
import Logo from '@images/logo.svg';
import Navigation from '@components/Navigation';
import type { WagtailMainMenu } from '@/types';
import { WagtailImage } from '@/types';
import Image from 'next/legacy/image';
import { addBaseUrl } from '@/utils/url';
import RawHtml from '@components/RawHtml';

type HeaderProps = {
  svg: string;
  ariatext: string;
  image: WagtailImage | null;
  warning: string;
};

type Props = {
  data: WagtailMainMenu;
  header?: HeaderProps | null;
};

const Header = (props: Props) => {
  const { data, header } = props;

  return (
    <>
      <header className="header">
        <Link
          href="/"
          passHref
          className="header__link"
          aria-label="Link auf die Startseite"
        >
          {header?.image ? (
            <Image
              src={addBaseUrl(header.image.meta.download_url)}
              alt={header.image.title}
              layout="fixed"
              className="socialicon"
              objectFit="contain"
              width={40}
              height={40}
            />
          ) : header?.svg ? (
            <RawHtml className="socialicon" html={header.svg} />
          ) : (
            <Logo className="header__logo" />
          )}
        </Link>
        <Navigation data={data} />
      </header>
      {header?.warning ? (
        <RawHtml className="headerwarning" html={header.warning} />
      ) : (
        ''
      )}
    </>
  );
};

export default Header;
